export const AuthTypes = {
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_FAILURE: 'LOGIN_FAILURE',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',

	LOGOUT_REQUEST: 'LOGOUT_REQUEST',
	LOGOUT_FAILURE: 'LOGOUT_FAILURE',
	LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

	SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
	SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',
	SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',

	BUTTON_LOADER_ON: 'BUTTON_LOADER_ON',
	BUTTON_LOADER_OFF: 'BUTTON_LOADER_OFF',

	VERIFICATION_REQUEST: 'VERIFICATION_REQUEST',
	VERIFICATION_SUCCESS: 'VERIFICATION_SUCCESS',
	VERIFICATION_FAILURE: 'VERIFICATION_FAILURE',

	DELETE_ACCOUNT_REQUEST: 'DELETE_ACCOUNT_REQUEST',
	DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
	DELETE_ACCOUNT_FAILURE: 'DELETE_ACCOUNT_FAILURE',
};

export const ResourceType = {
	ADD_RESOURCE_REQUEST: 'ADD_RESOURCE_REQUEST',
	ADD_RESOURCE_FAILURE: 'ADD_RESOURCE_FAILURE',
	ADD_RESOURCE_SUCCESS: 'ADD_RESOURCE_SUCCESS',
};

export const ProjectType = {
	PROJECT_API_CALL: 'PROJECT_API_CALL',
	PROJECT_API_CALL_OFF: 'PROJECT_API_CALL_OFF',
	PROJECT_API_LOADER_ON: 'PROJECT_API_LOADER_ON',
	PROJECT_API_LOADER_OFF: 'PROJECT_API_LOADER_OFF',

	ADD_PROJECT_REQUEST: 'ADD_PROJECT_REQUEST',
	ADD_PROJECT_FAILURE: 'ADD_PROJECT_FAILURE',
	ADD_PROJECT_SUCCESS: 'ADD_PROJECT_SUCCESS',

	GET_ALL_PROJECT_REQUEST: 'GET_ALL_PROJECT_REQUEST',
	GET_ALL_PROJECT_FAILURE: 'GET_ALL_PROJECT_FAILURE',
	GET_ALL_PROJECT_SUCCESS: 'GET_ALL_PROJECT_SUCCESS',

	START_PROJECT_FETCH_LOADER: 'START_PROJECT_FETCH_LOADER',
	STOP_PROJECT_FETCH_LOADER: 'STOP_PROJECT_FETCH_LOADER',

	CURRENT_PROJECT_INFO: 'CURRENT_PROJECT_INFO',
};

export const PeopleTypes = {
	PEOPLE_API_CALL: 'PEOPLE_API_CALL',
	PEOPLE_API_CALL_OFF: 'PEOPLE_API_CALL_OFF',
	PEOPLE_API_LOADER_ON: 'PEOPLE_API_LOADER_ON',
	PEOPLE_API_LOADER_OFF: 'PEOPLE_API_LOADER_OFF',
	PEOPLE_API_DATA: 'PEOPLE_API_DATA',
	PEOPLE_API_MOADL_ON: 'PEOPLE_API_MOADL_ON',
	PEOPLE_API_MOADL_OFF: 'PEOPLE_API_MOADL_OFF',
	PEOPLE_MOADL_ON: 'PEOPLE_MOADL_ON',
	PEOPLE_MOADL_OFF: 'PEOPLE_MOADL_OFF',
};

export const ToDoTypes = {
	TODO_API_CALL: 'TODO_API_CALL',
	TODO_API_CALL_OFF: 'TODO_API_CALL_OFF',
	TODO_API_LOADER_ON: 'TODO_API_LOADER_ON',
	TODO_API_LOADER_OFF: 'TODO_API_LOADER_OFF',
	TODO_API_DATA: 'TODO_API_DATA',
	TODO_MENTIONS_API_DATA: 'TODO_MENTIONS_API_DATA',
};

export const RolesTypes = {
	ROLES_API_CALL: 'ROLES_API_CALL',
	ROLES_API_CALL_OFF: 'ROLES_API_CALL_OFF',
	ROLES_API_LOADER_ON: 'ROLES_API_LOADER_ON',
	ROLES_API_LOADER_OFF: 'ROLES_API_LOADER_OFF',
	ROLES_API_DATA: 'ROLES_API_DATA',
	ROLES_MODAL_ON: 'ROLES_MODAL_ON',
	ROLES_MODAL_OFF: 'ROLES_MODAL_OFF',
	ROLES_GLOBAL_MODAL_ON: 'ROLES_GLOBAL_MODAL_ON',
	ROLES_GLOBAL_MODAL_OFF: 'ROLES_GLOBAL_MODAL_OFF',
};

export const DashboardTypes = {
	DASHBOARD_API_CALL: 'DASHBOARD_API_CALL',
	DASHBOARD_API_CALL_OFF: 'DASHBOARD_API_CALL_OFF',
	DASHBOARD_API_LOADER_ON: 'DASHBOARD_API_LOADER_ON',
	DASHBOARD_API_LOADER_OFF: 'DASHBOARD_API_LOADER_OFF',
	DASHBOARD_API_DATA: 'DASHBOARD_API_DATA',
	DASHBOARD_CURRENT_PROJECT: 'DASHBOARD_CURRENT_PROJECT',
	DASHBOARD_MILESTONE_MODAL_ON: 'DASHBOARD_MILESTONE_MODAL_ON',
	DASHBOARD_MILESTONE_MODAL_OFF: 'DASHBOARD_MILESTONE_MODAL_OFF',
	DASHBOARD_TASK_MODAL_OFF: 'DASHBOARD_TASK_MODAL_OFF',
	DASHBOARD_TASK_MODAL_ON: 'DASHBOARD_TASK_MODAL_ON',
	DASHBOARD_TASK_FETCH: 'DASHBOARD_TASK_FETCH',
	DASHBOARD_SET_CURRENT_MILESTONE: 'DASHBOARD_SET_CURRENT_MILESTONE',
	DASHBOARD_CURRENT_PROJECT_NAME: 'DASHBOARD_CURRENT_PROJECT_NAME',
	DASHBOARD_TASK_MODAL_VIEW_ON: 'DASHBOARD_TASK_MODAL_VIEW_ON',
	DASHBOARD_TASK_MODAL_VIEW_OFF: 'DASHBOARD_TASK_MODAL_VIEW_OFF',
};
